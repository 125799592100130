.layout {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  font-size: 16px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 1rem;
  gap: 1rem; 
}
.logo {
  width: 80px; 
  height: auto;
  margin-right: 2rem;
}
.navLinks {
  display: flex;
  gap: 1rem;
  justify-self: start; 
}

.navLink {
  color: var(--brand-1);
  /* transition: color 0.2s ease; */
  border-radius: 0.375rem;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
}

.navLink:hover {
  color: var(--brand-5);
}

.authButtons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-self: end;
}

.loginLink {
  transition: color 0.2s ease;
  border-radius: 0.375rem;
  padding: 0.5rem 0.25rem;
}

.registerButton {
  color: white;
  transition: all 0.2s ease;
  padding: 1rem 1rem;
  border-radius: 100px;
  white-space: nowrap;
}

.registerButton:hover {
  background-color: blue;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .navLinks {
    display: none;
  }

  .header {
    padding: 0.75rem 1rem;
  }

  .authButtons {
    gap: 0.25rem;
  }

  .loginLink,
  .registerButton {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
  }
}