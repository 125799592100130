
  .gridStep0Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
  }


  .gridStep1Container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 4px;
  }


  .column {
    background-color: #fff;
    /* border-radius: 8px 0 0 8px; */
    overflow: hidden;
  }

  .header {
    padding: 12px 20px;
  }

  .header h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
  }


  .checkboxLabel {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .checkbox {
    appearance: auto;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }

  .checkbox:checked {
    background-color: rgb(10, 218, 10);
    border-color: rgb(10, 218, 10);
  }

  @media (max-width: 768px) {
    .gridStep0Container {
      grid-template-columns: 1fr;
    }
    .gridStep1Container {
      grid-template-columns: 1fr;
    }
    .column {
        border-radius: 16px 16px 16px 16px;
      }
  }