    .indicator {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 0.25rem 2rem;
        border-radius: 100px;
        background: white;
        flex-grow: 1;
        margin-bottom: 0.5rem;
        position: relative;
        font-size: 1rem;
    }


    /* .step-number.completed {
        background: var(--brand-color);
    }

    .step-number.inactive {
        background: gray;
    } */

    .label {
        font-size: 0.875rem;
    }

    /* .step-label.active {
    color: black;
    }

    .step-label.inactive {
    color: gray;
    } */
    @media (max-width: 768px) {
        .indicator {
            flex-direction: column;
            gap: 0.5rem;
        }

        .item {
            padding: 0.5rem 1rem; 
            width: 100%;
            font-size: 0.875rem;
        }

        .label {
            font-size: 0.75rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
        }
    }
    
    @media (max-width: 480px) {
        .item {
            padding: 0.4rem 0.8rem;
        }
        
        .label {
            max-width: 120px;
        }
    }
